<template>
  <ResultItem v-bind="forwarded" @click="if (isClickable) navigateTo(toSinglePage(`/talents/${talent.id}`));">
    <ResultItemIcon :icon="mapIcon.talent" />
    <ResultItemContent>
      <ResultItemTitle> {{ getFullName(talent) }} </ResultItemTitle>
      <ResultItemText v-if="address">
        {{ address.formatted }}
      </ResultItemText>
      <ResultItemText v-if="showBirthDate && talent.birth_date">
        Né(e) le {{ dateFormat(talent.birth_date, { options: { dateStyle: "medium" } }) }}
      </ResultItemText>
      <ResultItemText v-if="talent.email">{{ talent.email }}</ResultItemText>
      <ResultItemText v-if="talent.phone_number">
        {{ new AsYouType().input(talent.phone_number) }}
      </ResultItemText>
    </ResultItemContent>
  </ResultItem>

  <LazyTalentSheet
    v-if="showTalentSheet && fullTalent"
    :talent-id="fullTalent.id"
    @on-close="showTalentSheet = false"
  />
</template>

<script setup lang="ts">
import type { Database } from "@asap/shared/src/types/supabase.gen";
import { AsYouType } from "libphonenumber-js";
import { useForwardPropsEmits } from "radix-vue";
import type { BaseTalent, Talent } from "@asap/shared/src/utils/supabase.types";
import type { ResultItemProps, ResultItemEmits } from "~/components/result-item/types";
import { dateFormat } from "@asap/shared/src/utils/date";
import { getFullName } from "@asap/shared/src/utils/people";
import { talentColumns } from "@asap/shared/src/utils/supabase.columns";

type Props = ResultItemProps & { talent: BaseTalent; showBirthDate?: boolean };

const props = defineProps<Props>();
const emits = defineEmits<ResultItemEmits>();
const forwarded = useForwardPropsEmits(props, emits);

const showTalentSheet = ref(false);

const supabase = useSupabase();

const fullTalent = ref<Talent>();

type AddressTableInsert = Database["public"]["Tables"]["address_table"]["Row"];

const address = ref<AddressTableInsert>();

onMounted(async () => {
  const { data: talent, error } = await supabase
    .from("talent")
    .select(talentColumns)
    .eq("id", props.talent.id)
    .single();

  if (error) throw error;

  fullTalent.value = talent;

  if (!talent.address_id) return;

  const { data: addressData, error: addressError } = await supabase
    .from("address_table")
    .select()
    .eq("id", talent.address_id)
    .single();

  if (addressError) throw addressError;

  address.value = addressData;
});
</script>
